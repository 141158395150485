import { useWeb3React } from '@web3-react/core'
import { Grid, Image } from 'antd'
import { NORMAL_INTERVAL } from 'config/constants'
import { useTranslation } from 'contexts/Localization'
import useGetPoolAmountSwr from 'hooks/swr/useGetPoolAmountSwr'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import { setModalConnect } from 'state/modal/actions'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'
import { TextGradient } from 'widgets'
import BaseButton from 'widgets/BaseButton'

const { useBreakpoint } = Grid

const Banner = () => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const dispatch = useDispatch()
  const router = useRouter()

  const { account } = useWeb3React()
  const { data: totalPool } = useGetPoolAmountSwr({ refreshInterval: NORMAL_INTERVAL })

  const toggleWallet = () => dispatch(setModalConnect({ toggle: true }))

  const handleBtnClick = () => {
    if (!account) return toggleWallet()
    return router.push('/my-nft/mint')
  }

  return (
    <div className="flex flex-col items-center w-full md:pt-[100px] pt-[80px] pb-[60px] relative gap-[20px]">
      <Image
        preview={false}
        src="/images/home/banner/babycate.png"
        width={breakpoint?.md ? '20%' : '40%'}
        className="max-w-[300px] mx-auto relative z-[1]"
      />
      <Image
        preview={false}
        src="/images/home/banner/lottery.png"
        width={breakpoint?.md ? '55%' : '80%'}
        className="max-w-[784px] mx-auto md:mt-[-40px] mt-[-20px] mb-[-10px] relative z-[1]"
      />

      <RewardCard className="flex flex-col items-center gap-3 sm:min-w-[400px] min-w-[95%] max-w-[800px] relative z-[2]">
        <h2 className="lg:text-[28px] md:text-[24px] text-[18px] font-bold">{t("Total Reward")}</h2>

        <div className="flex items-center justify-center gap-2 flex-wrap">
          <TextGradient
            bold
            fontSize={breakpoint?.md ? '32px' : '18px'}
            color="linear-gradient(180deg, #FFB905 37.76%, #FFEE97 62.67%, #FFB905 87.75%)"
            style={{ margin: 0, wordBreak: 'break-all' }}
            textAlign="center"
          >
            {!totalPool ? '--' : addCommasToNumber(totalPool)}
          </TextGradient>
          <img src="/logo.png" alt="" className="w-[32px] aspect-square" />
        </div>
      </RewardCard>

      <BaseButton className="w-full md:max-w-[300px] max-w-[160px] !py-4 relative z-[1]" onClick={handleBtnClick}>
        <p className="lg:text-[32px] md:text-[24px] text-[18px] text-[#021D00]">
          {account ? t('Buy Ticket') : t('Connect Now')}
        </p>
      </BaseButton>

      <Image
        preview={false}
        src="/images/home/banner/right.png"
        width="80%"
        className="absolute right-0 bottom-[-60px] z-0 translate-x-[70%] max-w-[1500px]"
      />
      <Image
        preview={false}
        src="/images/home/banner/left.png"
        width="80%"
        className="absolute left-0 bottom-[-60px] z-0 translate-x-[-70%] max-w-[1500px]"
      />
    </div>
  )
}

export default Banner

const RewardCard = styled.div`
  padding: 16px;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0);
  background: linear-gradient(114deg, #0d021c -43.49%, #112161 90.99%);
  box-shadow: 0px 0px 140px 0px rgba(34, 7, 195, 0.44);
  backdrop-filter: blur(1.4px);
`
