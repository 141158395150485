import nftTicketAbi from 'config/abi/nftTicket.json'
import useSWR, { SWRConfiguration } from 'swr'
import { getNftTicketAddress } from 'utils/addressHelpers'
import Web3Contract from 'utils/Web3Token'
import SWR_KEY from './constant'

const { contract: contractMethod } = new Web3Contract(getNftTicketAddress(), nftTicketAbi)

const useGetPoolAmountSwr = (options?: SWRConfiguration) => {
  const fetchData = async () => contractMethod.methods.POOL_AMOUNT().call().then(res => +res / 1e9)

  return useSWR(contractMethod?._address ? [SWR_KEY.GET_TICKET_PRICE, contractMethod?._address] : null, fetchData, {
    revalidateOnFocus: false,
    ...options,
  })
}

export default useGetPoolAmountSwr
